import React from 'react';
import '../../styles/map.scss';

function ContactUs() {
  return (
    <div id="map"> 
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40865.483020904314!2d-5.417321495593013!3d50.196798365244575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486adfcfba9448c1%3A0xd625c859ed90d90f!2sMotekso%20Device%20Repair!5e0!3m2!1sen!2suk!4v1720907700153!5m2!1sen!2suk" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div> 
  );
}

export default ContactUs;
