import React from 'react';
import '../../styles/contact-us.scss';
import Map from '../Map/Map';

function ContactUs() {
  return (
    <section id="contact-us" className="parallax">
      <div className="container">
        {/* <h2>Contact Us</h2> */}
        <table>
          <tr>
            <td colspan="3" className="map">
              <Map />
            </td>
            <td id="contact-address">
              <div>
                <p>9 Rock Close
                <br/>Pengegon
                <br/>Camborne
                <br/>Cornwall
                <br/>TR14 7TT
                </p>
             </div>
            </td>
            <td id="#contact-details">
              <div id="contact-text">
              <h3>Phone</h3>
                <p id="phone"><a rel="noopener" target="_blank" href='tel:(+44)7506595309'>(+44)7506 595 309</a></p>
                <h3>Email</h3> 
                <p id="email"><a href="mailto:repairs@motekso.co.uk">repairs@motekso.co.uk</a></p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
  );
}

export default ContactUs;
