import React from 'react';
import '../../styles/hero.scss';

const Hero = () =>  {
  return (
    <section id="hero" className="parallax">
      <div className="hero-content">
          <div class="hero-text">
            {/* <img alt="Motekso Logo" src={MoteksoLogo}/> */}
            <h1>Affordable and Efficient Device Repair</h1><br/>
          </div>
      </div>  
    </section>
  );
}

export default Hero;

