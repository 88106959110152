import React from 'react';

function About() {
  return (
    <section id="about">
<div className="container">
  {/* <h2>About Us</h2> */}
  <h2>We can repair your device and the whole process is in full control of the customer no surprise fees or charges</h2>
  <h2>Our opening times are out of hours meaning you can get your device fix after work and we aim to get your device back to you the same day</h2>
  {/* <p>
    For over 12 years, we have specialized in the repair and modification of various devices, initially focusing on gaming consoles, computers, and phones.
    <br/>We previously worked under the name Zoom Devices but over time this evolved into Motekso standing for Modern Tech Solutions covering a broader area of technical business.
    <br />Our founder has an extensive background in Software Design and Development and holds a Certificate of Higher Education in Computer Science.
    <br />This comprehensive understanding of both hardware and software allows us to provide exceptional repair services, ensuring that devices are treated with precision rather than merely swapping out parts indiscriminately.
    <br />Our mission is to accurately diagnose and repair issues while preserving as many original components and functionalities as possible, thereby enhancing device performance and saving our customers money.
    <br />We strive to deliver a seamless experience, offering timely and affordable repairs.
    <br />To facilitate this, we have implemented a booking system that ensures we have the necessary parts in stock, guaranteeing a swift turnaround for your repairs.
  </p> */}
</div>

    </section>
  );
}

export default About;
