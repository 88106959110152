import React, {useState} from 'react';
import Header from './components/Common/Header';
import Hero from './components/HomePage/HeroSection';
import About from './components/Common/About';
import Services from './components/Common/Services';
// import Testimonials from './components/Common/Testimonials';
import ContactUs from './components/Common/ContactUs';
import BookNow from './components/Common/BookNow';
import Footer from './components/Common/Footer';

function App() {
  const [bookRepair, setBookRepair] = useState(false);

  const handleClick = () => {
    if (bookRepair === true) {
      setBookRepair(false);

    } else {
      setBookRepair(true);
    }
  };

  return (
    <div>
      <Header />
      <Hero handleClick={handleClick} bookRepair={bookRepair}/>
      <About />
      {/* <BookNow /> */}
      <ContactUs />
      {/* <Testimonials /> */}
      <Footer />
    </div>
  );
}

export default App;
