import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../assets/images/MoteksoLogo.png'; // Tell webpack this JS file uses this image
import '../../styles/styles.scss';

function Header() {
  console.log(logo);
  return (
    <Navbar bg="light" variant="light" expand="lg" sticky="top">
      <Navbar.Brand style={{display: 'block', marginLeft: '40px'}} href="#home"><img width="150px" height="35px" src={logo} alt="Motekso Logo" /></Navbar.Brand>
      {/* <Navbar.Toggle style={{marginRight: '20px'}} aria-controls="basic-navbar-nav" /> */}
      <Navbar.Collapse id="basic-navbar-nav">
          <Nav style={{marginRight: '20px'}}  className="ml-auto">
            <Nav.Link href="#hero">Home</Nav.Link> 
            <Nav.Link href="#about">About Us</Nav.Link> 
            {/* <Nav.Link href="#services">Services</Nav.Link>  */}
            {/* <Nav.Link href="#book">Book a Repair</Nav.Link> */}
            <Nav.Link href="#contact-us">Contact Us</Nav.Link>
            {/* <Nav.Link href="#faq">FAQ</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
