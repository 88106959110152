import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="container">
        <center>
        <p>© Motekso 2024</p>
        </center>
        {/* <p>Contact Us: [Phone Number] | [Email]</p> */}
        {/* <ul className="social-media">
          <li><a href="#">Facebook</a></li>
          <li><a href="#">Twitter</a></li>
          <li><a href="#">Instagram</a></li>
        </ul> */}
        {/* <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#book">Book a Repair</a></li>
            <li><a href="#contact">Contact Us</a></li>
            <li><a href="#faq">FAQ</a></li>
          </ul>
        </nav> */}
      </div>
    </footer>
  );
}

export default Footer;
